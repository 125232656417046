<template>


    <b-modal id="all-badges" centered title="Badges" title-class="font-18" hide-footer hide-header-close @shown="create">
      <div class="row" style="max-height: 400px;overflow-y: scroll">
            <div v-for="badge in badges_p" :key="badge.id" class="col-xl-6">
                <div class="card border">
                    <div class="card-body text-center">
                                <b-avatar :src="badge.avatar" size="lg"  v-if="badge.avatar" style="cursor: pointer;" ></b-avatar>
                                <h5 class="text-truncate my-2 text-white font-size-15 link">{{badge.title}}</h5>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
    export default {
        name: "userBadges",
        props:['badges'],
      data(){
        return{
          badges_p:[]
        }
      },
        methods:{
          create(){
            this.badges_p = this.badges.map(x => x.badge);
          }
        }

    }
</script>

<style scoped>
    button{
        width: 150px;
    }
    @media screen and (max-width: 900px) {
        table tr{
            display: grid;
        }

    }
    html {
      --scrollbarBG: #CFD8DC;
      --thumbBG: #90A4AE;
    }
    div::-webkit-scrollbar {
      width: 8px;
    }
    div {
      scrollbar-width: thin;
      scrollbar-color: var(--thumbBG) var(--scrollbarBG);
    }
    div::-webkit-scrollbar-track {
      background: none;
    }
    div::-webkit-scrollbar-thumb {
      background-color: var(--thumbBG) ;
      border-radius: 6px;
    }
</style>