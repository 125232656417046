<script>

import PageHeader from "@/components/structure/page-header";
import appConfig from "@/app.config.json";
import ProfileHeader from "@/components/profileHeader";
import Post from "@/components/post";
import helper from "@/assets/helpers/helper";
import Evolution from "../../components/evolution";
import ChallSolvedByCat from "../../components/challSolvedByCat";


/**
 * Contacts-Profile component
 */
export default {
  page: {
    title: "Profile User",
    meta: [{ name: "description", content: appConfig.description }]
  },
  name:'ProfilUser',
  components: {ChallSolvedByCat, Evolution, Post, ProfileHeader, PageHeader },
  data() {
    return {
      title: "Profile User",
      page_posts:1,
      posts: [],
      settings: {
        "dots": true,
        "edgeFriction": 0.35,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 2,
        "slidesToScroll": 1
      },
      profil_info: {},
      badges:[]
    }
  },
  created(){
    this.loadData()
  },
  methods: {

    async loadData() {

      this.profil_info = await helper.getAll('users/' + this.$route.params.id);
      document.title = this.profil_info.username + ' | CRISIS'
      this.badges = await helper.getAll('users/' + this.$route.params.id+'/badges');

    },

    infiniteHandler($state) {
      this.$http.get('users/'+this.$route.params.id+'/posts?page=' +this.page_posts).then(({ data }) => {
        if (data.length) {
          this.page_posts += 1;
          this.posts.push(...data);
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },
  }
};
</script>

<template>
  <div>
    <PageHeader :title="$t('profil_user.title')"  />
    <div class="row ">
      <div class="col-lg-12">
        <profile-header :info="profil_info" :badges="badges"></profile-header>
      </div>
    </div>

    <div class="row  ">
      <div class="col-xl-3 d-none d-md-block" >
        <evolution/>
      </div>
      <div class="col-xl-5">
        <b-tabs pills justified content-class="pt-3 text-muted">
          <b-tab  class="border-0">
            <template v-slot:title>
              <span class=" d-sm-inline-block ">{{$t('profil_user.posts')}}</span>
            </template>
            <div>
          <!-- Posts  -->
          <div v-for="post of posts" :key="post.id">
            <post :post="post" ></post>

          </div>
          <infinite-loading @infinite="infiniteHandler">
            <div slot="no-more">{{$t('validation.no_more_posts')}}</div>
            <div slot="no-results">{{$t('validation.no_posts')}}</div>
          </infinite-loading>

        </div>
          </b-tab>
          <b-tab  class="border-0">
            <template v-slot:title>
              <span class=" d-sm-inline-block ">{{$t('profil_user.settings.social.title')}}</span>
            </template>
            <div class="row card">

              <div class="col-xl-12 pt-3">
                <h6 class="mt-2">HackerOne</h6>
                <b-input-group class="my-3">
                  <template #prepend>
                    <b-input-group-text>
                      <i class="fab fa-hackerrank"></i>
                    </b-input-group-text>
                  </template>
                  <template #append v-if="profil_info.username_github">
                    <b-input-group-text style="cursor: pointer">
                      <a :href="'https://hackerone.com/'+profil_info.username_hackerone" target="_blank"> <i class="fas text-primary fa-arrow-right"></i></a>

                    </b-input-group-text>
                  </template>
                  <b-form-input type="text"  v-model="profil_info.username_hackerone" :placeholder="(profil_info.username_hackerone)? '':'none'" disabled></b-form-input>
                </b-input-group>
                <h6> Github</h6>
                <b-input-group class="my-3">
                  <template #prepend>
                    <b-input-group-text>
                      <i class="fab fa-github" ></i>
                    </b-input-group-text>
                  </template>
                  <template #append v-if="profil_info.username_github">
                    <b-input-group-text style="cursor: pointer">
                      <a :href="'https://github.com/'+profil_info.username_github" target="_blank"> <i class="fas text-primary fa-arrow-right"></i></a>
                    </b-input-group-text>
                  </template>
                  <b-form-input type="text"  v-model="profil_info.username_github" :placeholder="(profil_info.username_github)? '':'none'" disabled></b-form-input>
                </b-input-group>
                <h6 >Linkedin</h6>
                <b-input-group class="my-3">
                  <template #prepend>
                    <b-input-group-text>
                      <i class="fab fa-linkedin"></i>
                    </b-input-group-text>
                  </template>
                  <template #append v-if="profil_info.username_github">
                    <b-input-group-text style="cursor: pointer">
                      <a :href="'https://www.linkedin.com/in/'+profil_info.username_linkedin"> <i class="fas text-primary fa-arrow-right"></i></a>
                    </b-input-group-text>
                  </template>
                  <b-form-input type="text"  v-model="profil_info.username_linkedin" :placeholder="(profil_info.username_linkedin)? '':'none'" disabled></b-form-input>
                </b-input-group>


              </div>

            </div>
          </b-tab>
        </b-tabs>

      </div>
      <div class="col-xl-4 d-none d-md-block">
        <chall-solved-by-cat/>
      </div>
    </div>
    <!-- end row -->
  </div>
</template>
