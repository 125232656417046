<template>
    <div class="card">
    <div class="card-body">
        <div class="clearfix">
            <h4 class="card-title mb-4">{{$t('profil_user.score_evolution')}}</h4>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <apexchart
                        class="apex-charts"
                        dir="ltr"
                        height="300"
                        :series="user_evolution.series"
                        :options="user_evolution.chartOptions"
                ></apexchart>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
    import helper from "@/assets/helpers/helper";
    export default {
        name: "evolution",
        data(){
            return{
                user_evolution : {
                    series: [{
                        name: 'Number',
                        data: []
                    }],
                    chartOptions: {
                        chart: {
                            height: 320,
                            type: 'line',
                            toolbar: 'false',
                            dropShadow: {
                                enabled: true,
                                color: '#000',
                                top: 18,
                                left: 7,
                                blur: 8,
                                opacity: 0.2
                            },
                        },
                        dataLabels: {
                            enabled: false
                        },
                        yaxis: {
                            tickAmount: 1,
                            labels: {
                                formatter: function(val) {
                                    return val.toFixed(0)
                                }
                            },
                            title: {
                                text: 'Score'
                            },
                        },

                        colors: ['#556ee6'],
                        stroke: {
                            curve: 'smooth',
                            width: 3,
                        },
                    }
                },
                months : [ "January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December" ],
            }
        },
        created(){
            this.loadEvolution()
        },
        methods:{
           async loadEvolution(){
               let link = ''
               if(this.$route.params.id) link = 'users/'+this.$route.params.id
               else link = 'me'
                let evolution = await helper.getAll(link+'/evolution');
               let result = {}
               let labels = []
               for (var i = 0; i < evolution.data.length; i++) {
                   labels.push(Object.keys(evolution.data[i]));
                   result[Object.keys(evolution.data[i])] =  Object.values(evolution.data[i])[0];
               }
               this.$set(this.user_evolution.series, 0, {name: 'Number', data: Object.values(result)})
               this.user_evolution.chartOptions = {
                   ...this.user_evolution.chartOptions, ...{
                       labels: labels
                   }
               }
            }
        }
    }
</script>

<style scoped>

</style>