<template>
    <div class="card">
        <div class="card-body">
            <div class="row align-items-center" v-if="info.username">
                <div class="col-lg-3 col-xl-3 col-md-6 col-sm-12 mb-2">
                    <div class="media">
                        <div class="mr-2">
                            <b-avatar :src="info.avatar" size="lg"  v-if="info.avatar" ></b-avatar>
                            <b-avatar v-else variant="primary" size="lg" :text="(info.username).substr(0, 1)" class="align-baseline "></b-avatar>

                        </div>
                        <div class="media-body align-self-center">
                            <div class="text-muted">
                                <h5 class="mb-1" >{{info.username}}</h5>
                                <p class="mb-0 ml-1">{{info.country}}</p>
                            </div>
                            <b-avatar-group class="mt-2" size="2rem" v-if="badges.length" role="button"  v-b-modal.all-badges >
                                <b-avatar :src="badges[0].badge.avatar"></b-avatar>
                                <b-avatar :src="badges[1].badge.avatar" v-if="badges.length >=2"></b-avatar>
                                <b-avatar :src="badges[2].badge.avatar" v-if="badges.length >=3"></b-avatar>
                                <b-avatar :text="'+'+(badges.length-3).toString()" style="background: #556ee6!important" v-if="badges.length >=4"></b-avatar>
                            </b-avatar-group>
                        </div>
                    </div>
                </div>

                <div class="col-lg-8 col-xl-8 col-sm-12 col-md-6 mb-2 mt-lg-0  text-lg-center align-self-center">

                        <div class="row align-items-center justify-content-center">
                            <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 mb-2 w-50">
                                    <p class="text-muted   mb-2">{{$t('profil_team.score')}}</p>
                                    <h5 class="mb-0">{{info.score?info.score:0}}</h5>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 mb-2 w-50" v-if="info.team && info.team.length!=0 ">
                                    <p class="text-muted  mb-2" >{{$t('profil_user.team')}}</p>

                                    <router-link class="h5 mb-0 link" v-if="info.team && info.team.length!=0" :to="{name:'ProfilTeam',params:{id:info.team[0].id}}">{{info.team[0].name}}</router-link>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 mb-2 w-50">
                                    <p class="text-muted  mb-2">{{$t('profil_team.solved_chall')}}</p>
                                    <h5 class="mb-0">{{info.challenge_solved_count}}</h5>
                            </div>

                          <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 mb-2  w-50" v-if="info.country">
                            <p class="text-muted  mb-2">{{$t('profil_team.country_rank')}}</p>
                            <h5 class="mb-0">{{info.ranks.length?info.ranks[0].rank_country:'-'}}</h5>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 mb-2  w-50">
                            <p class="text-muted  mb-2">{{$t('profil_team.world_rank')}}</p>
                            <h5 class="mb-0">{{info.ranks.length?info.ranks[0].rank_world:'-'}}</h5>
                          </div>

                            <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 mb-2 w-50">
                                    <p class="text-muted mb-2">{{$t('profil_user.followers')}}</p>
                                    <h5 class="mb-0">{{info.followers_count}}</h5>
                            </div>

                        </div>

                </div>

                <div class="col-lg-1 col-xl-1  text-right" v-if="$store.state.user.id != info.id">
                    <button class="btn btn-primary btn-md "  @click="Follow" v-if="info.is_followed_by_me == 0">{{$t('profil_user.follow')}}
                    </button>
                    <button  class="btn btn-primary btn-md "  @click="Follow" v-if="info.is_followed_by_me == 1">{{$t('profil_user.unfollow')}}
                    </button>
                </div>


            </div>
            <div class="row" v-else>
                <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 mb-2 w-50">
                    <div class="row">
                        <b-skeleton type="avatar" class="col-sm-2 mr-2"></b-skeleton>
                        <div class="col-sm-9">
                            <b-skeleton class="mb-2"></b-skeleton>
                            <b-skeleton class="mb-2"></b-skeleton>
                        </div>
                    </div>


                </div>
                <div class="col-xl-1 col-lg-1 col-md-4 col-sm-6 mb-2 w-50">
                    <p class="text-muted   mb-2">{{$t('profil_team.score')}}</p>
                    <b-skeleton ></b-skeleton>
                </div>
                <div class="col-xl-1 col-lg-1 col-md-4 col-sm-6 mb-2 w-50">
                    <p class="text-muted mb-2">{{$t('profil_user.team')}}</p>
                    <b-skeleton ></b-skeleton>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 mb-2 w-50">
                    <p class="text-muted  mb-2">{{$t('profil_team.solved_chall')}}</p>
                    <b-skeleton ></b-skeleton>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 mb-2 w-50">
                    <p class="text-muted  mb-2">{{$t('profil_team.country_rank')}}</p>
                    <b-skeleton></b-skeleton>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 mb-2 w-50">
                    <p class="text-muted  mb-2">{{$t('profil_team.world_rank')}}</p>
                    <b-skeleton></b-skeleton>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 mb-2 w-50">
                    <p class="text-muted  mb-2">{{$t('profil_user.followers')}}</p>
                    <b-skeleton ></b-skeleton>
                </div>

            </div>
            <!-- end row -->
        </div>
        <user-badges :badges="badges"/>
    </div>
</template>

<script>

    import helper from "@/assets/helpers/helper";
    import UserBadges from "./popups/userBadges";

    export default {
        name: "profileHeader",
        components: {UserBadges},
        props:['info','badges'],
        methods:{
            async Follow() {
               let result =  await helper.postData('users/'+this.info.user_id+'/follow');
                if(result)
                {
                    if( this.info.is_followed_by_me) this.info.followers_count -= 1
                    else this.info.followers_count += 1
                    this.info.is_followed_by_me = 1 - this.info.is_followed_by_me

                }
            },
        }
    }
</script>

<style scoped>


</style>