<template>
    <div class="card">
        <div class="card-body">
            <h4 class="card-title mb-4">{{$t('profil_user.chall_solved_by_cat')}}</h4>
            <div id="revenue-chart" class="apex-charts"></div>
            <apexchart
                    class="apex-charts"
                    type="bar"
                    height="300"
                    :series="ChallSolvedByCatChart.series"
                    :options="ChallSolvedByCatChart.chartOptions"
            ></apexchart>
        </div>
    </div>
</template>

<script>
    import helper from "@/assets/helpers/helper";

    export default {
        name: "challSolvedByCat",
        data(){
            return{
                ChallSolvedByCatChart : {
                    series: [{
                        name: 'Number',
                        data: []
                    }],
                    chartOptions: {
                        chart: {
                            type: 'bar',
                            toolbar: {
                                show: false,
                            }
                        },
                        plotOptions: {
                            bar: {
                                horizontal: false,
                                columnWidth: '14%',
                                endingShape: 'rounded'
                            },
                        },
                        dataLabels: {
                            enabled: false
                        },
                        stroke: {
                            show: true,
                            width: 2,
                            colors: ['transparent']
                        },

                        fill: {
                            opacity: 1
                        },
                        yaxis: {
                            min: 0,
                            tickAmount: 1,
                            labels: {
                                formatter: function(val) {
                                    return val.toFixed(0)
                                }
                            },
                            title: {
                                text: 'Solves'
                            },
                        },
                        xaxis: {
                            title: {
                                text: 'Categories'
                            },
                        },
                        colors: ['#556ee6'],
                    }
                },
            }
        },
        created(){
            this.loadEvolution()
        },
        methods:{
            async loadEvolution(){
                let link = ''
                if(this.$route.params.id) link = 'users/'+this.$route.params.id
                else link = 'me'
                let solves = await helper.getAll(link+'/solves');

                this.$set(this.ChallSolvedByCatChart.series, 0, {name: 'Number', data: Object.values(solves.data)})

                let categories=[]
                Object.keys(solves.data).forEach(element => {
                    categories.push(element);
                });

                this.ChallSolvedByCatChart.chartOptions = {
                    ...this.ChallSolvedByCatChart.chartOptions, ...{
                        labels: categories
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>